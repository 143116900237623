.info-alert {
  display: flex;
  align-items: center;
  border: 1px solid #90caf9;
  border-radius: 8px;
  padding: 12px 16px;
  background-color: #e3f2fd;
  color: #1976d2;
  font-size: 16px;
  max-width: 100%;
}

.info-alert .icon {
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1976d2;
  background-color: #ffffff;
  border: 1px solid #90caf9;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
}

.info-alert .message {
  font-weight: 500;
  color: #000000;
}
