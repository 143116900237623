.input-style {
  width: 100%;
  padding: 0.75rem;
  border: 2px solid #38a169;
  border-radius: 8px;
  font-size: 1rem;
  color: #333;
  transition: border-color 0.3s ease;
  background-color: #ffffff;

  &:focus {
    border-color: #2f855a;
    outline: none;
    box-shadow: 0 0 5px rgba(47, 133, 90, 0.3);
  }

  &::placeholder {
    color: #a0aec0;
  }
}

.history-section {
  border-top: 1px solid #e2e8f0;
}

.history-section h2 {
  color: #2d3748;
}

.info-alert {
  display: flex;
  align-items: center;
  border: 1px solid #90caf9;
  border-radius: 8px;
  padding: 12px 16px;
  background-color: #e3f2fd;
  color: #1976d2;
  font-size: 16px;
  max-width: 100%;
}

.info-alert .icon {
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1976d2;
  background-color: #ffffff;
  border: 1px solid #90caf9;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
}

.info-alert .message {
  font-weight: 500;
  color: #1976d2;
}

.treatment-section {
  width: 100%;
  padding: 3%;
  background-color: #f0f7fc;
  border-radius: 15px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 5%;
  }

  input,
  select {
    margin-right: 1rem;
    padding: 0.75rem 1rem;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    font-weight: 700;
    color: grey;
    border: 1px solid #a0c4db;
    transition:
      box-shadow 0.3s,
      transform 0.2s;
    width: 100%;
    // max-width: 300px;

    @media (max-width: 576px) {
      margin-right: 0;
      margin-bottom: 1rem;
    }

    &:focus {
      box-shadow: 0 0 8px rgba(121, 167, 200, 0.6);
      transform: scale(1.02);
      outline: none;
    }
  }

  h2 {
    font-size: clamp(1.5rem, 4vw, 24px);
    text-transform: uppercase;
    font-weight: 700;
    color: #333;
    padding: 2rem 0;
    background: linear-gradient(to right, #79a7c8, #558a7e);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: 576px) {
      padding: 1rem 0;
    }
  }
}
