.search-autocomplete {
  position: relative;

  .suggestions-list {
    position: absolute;
    top: 115%;
    @media (max-width: 576px) {
      top: 100%;
    }
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
    list-style: none;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 10;

    .suggestion-item {
      padding: 10px;
      cursor: pointer;
      transition: background-color 0.2s;

      &:hover {
        background-color: #f0f0f0;
      }
    }
  }
}
