.table-div {
  width: 100%;
  overflow-y: scroll;
  padding: 10px;
  .check-table {
    table-layout: auto;
    border-collapse: collapse;

    th,
    td {
      padding: 8px;
      box-shadow: inset 0px 0px 0px 4px white;
      color: white;
      @media screen and (max-width: 768px) {
        font-size: 12px;
        height: 40px;
      }
    }
    th {
      background-color: #79a7c8;
    }
    .cell {
      background-color: #e0e0e0;
      box-shadow: inset 0px 0px 0px 4px white;
      color: black;
      text-align: center;
      text-align: -webkit-center;
      min-width: 80px;
      svg {
        height: 24px;
        width: 24px;
      }
    }

    .cell-disabled {
      background-color: #ddd;
      cursor: not-allowed;
    }

    tr {
      white-space: nowrap;
    }

    tr > th:first-child {
      font-weight: bold;
      position: sticky;
      left: 0;
      z-index: 1;

      @media screen and (max-width: 768px) {
        font-size: 12px;
      }
    }

    tr > td:first-child {
      background-color: #d4b1c2;
      color: white;
      padding: 8px;
      font-weight: bold;
      position: sticky;
      left: 0;
      z-index: 1;
      @media screen and (max-width: 768px) {
        font-size: 12px;
      }
    }

    .cell:hover {
      cursor: pointer;
      background-color: #cecece;
    }
  }
}

.cell-with-date {
  color: black;
  font-weight: bold;
  white-space: pre-line;
  padding: 10px;
  max-width: 150px;
  overflow-wrap: break-word;
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
}

.formatted-date {
  display: block;
  line-height: 1.5;
}
