.new-child-container > section {
    width: 100%;
    padding: 3%;
    background-color: #f0f7fc;
    border-radius: 15px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
        padding: 5%;
    }

    .table-container {
        margin-top: 2rem;
        padding: 2rem 2rem 1rem 2rem;
        background-color: white;
        border-radius: 10px;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
        @media (max-width: 576px) {
            padding: 1rem;
            margin-top: 1rem;
        }
    }

    #step1 {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;

        @media (max-width: 768px) {
            grid-template-columns: 1fr;
            gap: 0.5rem;
        }

        input {
            width: 100%;
        }

        div {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            label {
                font-weight: 600;
                color: #333;
            }
        }
    }

    input,
    select {
        margin-right: 1rem;
        padding: 0.75rem 1rem;
        box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        font-weight: 700;
        color: grey;
        border: 1px solid #a0c4db;
        transition:
            box-shadow 0.3s,
            transform 0.2s;
        width: 100%;
        // max-width: 300px;

        @media (max-width: 576px) {
            margin-right: 0;
            margin-bottom: 1rem;
        }

        &:focus {
            box-shadow: 0 0 8px rgba(121, 167, 200, 0.6);
            transform: scale(1.02);
            outline: none;
        }
    }

    h2 {
        font-size: clamp(1.5rem, 4vw, 24px);
        text-transform: uppercase;
        font-weight: 700;
        color: #333;
        padding: 2rem 0;
        background: linear-gradient(to right, #79a7c8, #558a7e);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;

        @media (max-width: 576px) {
            padding: 1rem 0;
        }
    }

    .create-button {
        width: 100%;
        text-align: right;
        margin-top: 2rem;

        > button {
            background-color: #79a7c8;
            color: white;
            padding: 0.5rem 2rem;
            font-weight: 700;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            transition: all 0.3s ease;
            width: auto;

            @media (max-width: 576px) {
                width: 100%;
                padding: 1rem;
            }

            &:hover {
                background-color: #558a7e;
                cursor: pointer;
                box-shadow: 0px 8px 16px rgba(85, 138, 126, 0.3);
                transform: translateY(-3px);
            }

            span {
                margin-left: 10px;
            }
        }
    }
}

.button-edit {
    background-color: #79a7c8;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 25px;
    font-weight: 700;
    display: inline-flex;
    align-items: center;
    transition: all 0.3s ease;

    @media (max-width: 576px) {
        // width: 100%;
        justify-content: center;
        padding: 1rem;
    }

    &:hover {
        background-color: #558a7e;
        cursor: pointer;
        box-shadow: 0px 6px 12px rgba(85, 138, 126, 0.3);
        transform: scale(1.05);
    }

    &:disabled {
        background-color: rgba(121, 167, 200, 0.5);
        cursor: default;
        box-shadow: none;
    }

    span {
        margin-left: 10px;
    }

    svg {
        width: 18px;
        height: 18px;

        line {
            stroke: white;
        }
    }
}
