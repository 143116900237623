.table-container {
  h2 {
    margin-bottom: 10px;
    padding: 0px !important;
    font-weight: 600;

    @media (max-width: 576px) {
      font-size: 1.25rem !important;
      font-weight: 500;
    }
  }

  .responsive-text {
    @media (max-width: 768px) {
      display: none;
    }
  }

  .styled-background-table {
    overflow: hidden;
    border-width: 1px;
    border-radius: 0.5rem;
    border-color: #d1d5db;
    margin-bottom: 2rem;
  }

  .styled-table {
    min-width: 100%;
    border-radius: 0.75rem;

    thead {
      border-color: rgb(209 213 219);
      background-color: #f9fafb;

      tr {
        background-color: #f9fafb;
      }
    }

    th {
      padding: 1.25rem;
      text-align: left;
      font-size: 0.875rem;
      line-height: 1.5rem;
      font-weight: 600;
      color: #111827;
      text-transform: capitalize;

      @media (max-width: 768px) {
        padding: 0.75rem;
      }
    }

    tbody {
      background-color: #fff;
      tr {
        border-bottom: 1px solid #d1d5db;
      }

      td {
        padding: 1.25rem;
        white-space: nowrap;
        font-size: 0.875rem;
        line-height: 1.5rem;
        font-weight: 500;
        color: #111827;

        @media (max-width: 768px) {
          padding: 0.75rem;
        }
      }
    }
  }

  .styled-table th:last-child,
  .styled-table td:last-child {
    border-right: none;
  }

  .buttons-block {
    display: flex;
    justify-content: flex-start;
    column-gap: 1rem;
    align-items: center;
    margin-top: 1rem;
  }

  .add-button {
    text-decoration: none;
    background-color: rgb(121 167 200);
    color: white;
    padding: 0.5rem 1rem;
    margin-bottom: 1.5rem;
    border-radius: 25px;
    font-weight: 700;
    display: inline-flex;
    align-items: center;
    text-align: center;
    // margin-top: 3rem;
    &:hover {
      background-color: rgba(121, 167, 200, 0.8);
      cursor: pointer;
    }
    span {
      margin-left: 10px;
    }
    svg {
      width: 18px;
      height: 18px;
      line {
        stroke: white;
      }
    }
    &:disabled {
      background-color: rgba(121, 167, 200, 0.5);
      cursor: default;
    }
  }

  .cancel-button {
    background-color: rgba(219, 53, 38, 0.9);
  }

  .cancel-button:hover {
    background-color: rgb(219, 53, 38, 1);
  }

  .add-container {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    column-gap: 3rem;

    // media query for tablet and under
    @media (max-width: 768px) {
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: flex-start;

      .add-item {
        // margin-bottom: 1rem;
        width: 100%;
      }
    }

    // @media (max-width: 576px) {
    //   flex-direction: column;
    //   flex-wrap: nowrap;
    //   align-items: flex-start;

    //   .add-item {
    //     // margin-bottom: 1rem;
    //     width: 100%;
    //   }
    // }
  }

  .edit-container {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    column-gap: 3rem;
    margin: 1rem 0;

    @media (max-width: 768px) {
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: flex-start;

      .edit-item {
        // margin-bottom: 1rem;
        width: 100%;
      }
    }
  }

  .edit-item {
    display: block;
    margin: 0 0 1rem 0;

    p {
      height: 2rem;
      margin-left: 2px;
      font-weight: 600;
    }
  }

  .add-item {
    display: block;
    margin: 0 0 1rem 0;

    p {
      height: 2rem;
      margin-left: 2px;
      font-weight: 600;
    }
  }
}

.button-choose {
  text-decoration: none;
  background-color: rgb(121 167 200);
  color: white;
  padding: 0.5rem 2rem;
  border-radius: 25px;
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }

  span {
    margin-left: 10px;
  }

  svg {
    width: 18px;
    height: 18px;
    line {
      stroke: white;
    }
  }

  &:disabled {
    background-color: rgba(121, 167, 200, 0.5);
    cursor: default;
  }
}

.button-select {
  text-decoration: none;
  background-color: rgb(121 167 200);
  color: white;
  padding: 0.5rem 2rem;
  border-radius: 25px;
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  margin-top: 3rem;
  &:hover {
    background-color: rgba(121, 167, 200, 0.8);
    cursor: pointer;
  }
  span {
    margin-left: 10px;
  }
  svg {
    width: 18px;
    height: 18px;
    line {
      stroke: white;
    }
  }
  &:disabled {
    background-color: rgba(121, 167, 200, 0.5);
    cursor: default;
  }
}

.action-buttons {
  display: flex;
  gap: 8px;
}

.button-delete {
  background-color: rgb(121 167 200);
  color: white;
  border: none;
  border-radius: 25px;
  padding: 0.5rem 1rem;
  font-weight: 700;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  span {
    margin-left: 10px;
  }
}

.button-delete:hover {
  background-color: rgba(200, 121, 121, 0.8);
  transform: scale(1.05);
}

.button-delete:disabled {
  background-color: rgba(121, 167, 200, 0.5);
  cursor: default;
}

.button-edit {
  background-color: rgb(121 167 200);
  color: white;
  border: none;
  border-radius: 25px;
  padding: 0.5rem 1rem;
  font-weight: 700;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.button-edit:hover {
  background-color: rgba(121, 167, 200, 0.8);
  transform: scale(1.05);
}

.button-edit:disabled {
  background-color: rgba(121, 167, 200, 0.5);
  cursor: default;
}
